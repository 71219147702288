.App {
  text-align: center;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.background-stripe-right {
  width: 80%;
  height: 40%;
  position: absolute;
  left: 45%;
  top: 35%;
}

.background-stripe-left {
  z-index: 1;
  width: 40%;
  height: 40%;
  position: absolute;
  right: 50%;
  top: 70%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 576px) {
  .home-button {
    height: 6vh !important;
    width: 60vw !important;
    font-size: 14px !important;
  }

  .description {
    height: 20vh !important;
    width: 80vw !important;
    margin-left: 0% !important;
  }

  .home-column {
    width: 100% !important;
  }

  .home-section {
    width: 100% !important;
  }

  .home-main {
    width: 80% !important;
    margin-left: 10% !important;
  }
}

@media only screen and (max-width: 768px) {
  .logo-container {
    width: 100%;
  }

  .logo {
    width: 40% !important;
  }
  .home-button {
    height: 6vh !important;
    width: 50vw !important;
    font-size: 16px !important;
  }
}

@media only screen and (min-width: 769px) and (max-width: 991px) {
  .logo-container {
    width: 100%;
  }

  .logo {
    width: 30% !important;
  }

  .home-button {
    height: 8vh !important;
    width: 50vw !important;
    font-size: 20px !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .logo-container {
    width: 100%;
  }

  .logo {
    width: 20% !important;
  }

  .home-main {
    width: 75% !important;
    margin-left: 12.5% !important;
  }

  .home-button {
    width: 100% !important;
  }

  .home-column {
    width: 100%;
  }

  .home-section {
    width: 40% !important;
  }
}

@media only screen and (min-width: 1200px) {
  .logo-container {
    width: 100%;
  }

  .logo {
    width: 15% !important;
  }

  .home-column {
    width: 100%;
  }
}

@media screen and (max-width: 1300px) {
  .background-stripe-right {
    z-index: 0;
  }
  
  .myLeaderboardPosition {
  background-color: #2dcd35 !important;
  }
}

