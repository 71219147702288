.quote-perk-container {
  background-color: white;
  width: 30vw;
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  height: 12vh;
  border-radius: 15px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  padding: 2%;
  margin-top: 3vh;
}

.quote-perk {
  margin: auto;
  font-weight: 900;
}

.perk-img-container {
  height: 80%;
  aspect-ratio: 1;
  margin: auto;
  margin-right:2vw
}

.form-card-quote {
  z-index: 1;
  background-color: white;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  width: 100vw;
  height: 100vh !important;
  display: flex;
  overflow-y: scroll;
}

.retrieval-back {
    height: 4vw;
    color: rgb(16, 149, 57);
    z-index: 9;
    position: relative;
    width: 5vw;
    right: 20vw;
    align-items: center !important;
    justify-content: center !important;
    flex-direction: row !important;
    width: 100% !important;
    display: flex !important;
}

.nav-bar {
  margin-left: 4rem !important;
  justify-content: space-between !important;
  flex-direction: row !important;
  width: 50% !important;
  display: flex !important;
}

.package-name{
  margin-top: 3rem !important;
  font-weight: 700;
}

.continue {
  height:20vh !important;
  width: 40% !important;
  padding-bottom: 3rem !important;
  margin-top: 3rem !important;
  margin-bottom: 1rem !important;
  margin-right: auto !important;
  margin-left: auto !important;
  justify-content: space-evenly !important;
  flex-direction: column !important;
  display: flex !important;
}

.utility-font{
  font-size: 16px;
  font-weight: 700 !important;
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.utility {
  margin-bottom: 0.25vh !important;
  margin-right: 0vw !important;
  margin-left: 0vw !important;
  border-radius: var(--bs-border-radius) !important;
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
  margin-right: 0.5rem !important;
  align-items: center !important;
  flex-direction: row !important;
  display: flex !important;
  width: 65%;
}

.benefits-section {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.pricing-container {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  margin-right: auto !important;
  margin-left: auto !important;
  align-items: center !important;
  flex-direction: column !important;
}

.icon-height {
  height: 3.5vh;
}

.price-unlimited{
  font-weight: 700 !important;
  color: #109539;
  font-size: 60px;
}

.price-lite{
  font-weight: 700 !important;
  color: #10958D;
  font-size: 60px;
}

.quote-card-selection {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  background-color: white;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  height:auto;
  width: 30vw !important;
  margin-left: 3vw;
  margin-right: 3vw;
}

.option-buttons {
  justify-content: center !important;
    flex-direction: row !important;
    width: 100% !important;
    display: flex !important;
}

.quote-card-selection:hover {
  box-shadow: 0 0.7rem 1.4rem rgba(0, 0, 0, 0.2);
}

.quote-card-selection:focus {
  border: 2px solid #0B9521;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
}

.background-stripe-right-quote {
  z-index: 1;
  position: absolute;
  height: 35vh;
}

.background-stripe-left-quote {
  z-index: 1;
  position: absolute;
  height: 35vh;
  left: 0;
}

.link {
  color: #0b9521;
  text-decoration: none;
}
.link:hover {
  color: #0b9521;
  text-decoration: none;
}

.utility-row {
  justify-content: center;
}

.discount-banner {
  background-color: #FF4040;
  padding: 2%;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  width: 55%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3%
}

.discount-banner-text{
  margin-top: auto;
  margin-bottom: auto;
  color: white;
  font-weight: 900;
  font-size: 16
}

@media only screen and (max-width: 576px) {
  .quote-card {
    width: 100% !important;
    height: 90vh !important;
    padding-top: 20% !important;
    border-width: 0px !important;
  }

  .form-card-quote {
    width: 100vw !important;
    height: 80vh !important;
    top: 0vh !important;
    display: inline-block;
  }

  .package-name{
    margin-top: 1rem !important;
    font-size: 24px;
  }

  .pricing-container {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }

  .quote-card-selection {
    width: auto !important;
    padding: 3%;
    margin-top: 1vw;
    margin-bottom: 2vw;
    margin-left: auto;
    margin-right: auto;
  }

  .top-row{
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: auto !important;
  }

  .price-unlimited{
    font-size: 40px;
    margin-bottom: 1px;
  }

  .price-lite{
    font-size: 40px;
  }

  .benefits-section {
    padding-top: 10px !important;
    padding-bottom: 2px !important;
    border-bottom: none !important;
  }

  .option-buttons {
    justify-content: center !important;
    height: 100% !important;
    /* flex-direction: column !important; */
    width: 100% !important;
    display: flex !important;
  }

  .retrieval-back {
    right: 35vw;
    top:3vh;
}

  .utility-font{
    font-size: 12px;
  }

  .icon-height {
    height: 3vh;
  }

  .house-image-quote {
    height: 15vh !important;
  }

  #house {
    left: 20vw !important;
    top: 15vh !important;
  }

  #house-big {
    display: none;
  }

  #coins {
    left: -40vw !important;
    top: 600px !important;
  }

  #coins-big {
    display: none;
  }

  .coins-image {
    height: 8vh !important;
  }

  .container,
  .utility-row {
    display: flex !important;
    flex-direction: column !important;
  }

  .utility {
    margin-bottom: 1vh !important;
    margin-right: 0vw !important;
    margin-left: 0vw !important;
    width: 100%
  }

  .background-stripe-left-quote {
    bottom: 5%;
    z-index: 0;
  }

  .background-stripe-right-quote {
    left: 42vw;
  }
}

@media only screen and (max-width: 460px) {

  .retrieval-back {
    right: 40vw;
    top:2.5vh;
}

.utility {
  margin-bottom: 0vh !important;
  margin-right: 0vw !important;
  margin-left: 0vw !important;
  padding-top: 0rem !important;
  padding-bottom: 0.5vh !important;
}

.continue {
  height: 25vh !important;
}
}


@media only screen and (max-height: 1200px) {

  .quote-card-selection {
    height:auto !important;
    width: auto !important;
    padding: 3%;
    margin-top: 1vw;
    margin-bottom: 2vw;
    margin-left: auto;
    margin-right: auto;
  }

}

@media only screen and (max-height: 900) {

  .form-card-quote{
    overflow-y: scroll !important;
  }

  .quote-card-selection {
    height:auto !important;
    width: auto !important;
    padding: 3%;
    margin-top: 1vw;
    margin-bottom: 2vw;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 576px) and (max-height: 750px) {
  .quote-card {
    padding-top: 25vh !important;
  }

  .form-card-quote {
    padding-top: 10% !important;
    overflow-x: scroll;
    height: 100vh !important;
    width: 100vw !important;
  }

  .quote-card-selection {
    width: auto !important;
    padding: 3%;
    margin-top: 1vw;
    margin-bottom: 2vw;
    margin-left: auto;
    margin-right: auto;
  }

}


@media only screen and (max-width: 576px) and (max-height: 970px) {
  .quote-card {
    padding-top: 25vh !important;
  }

  .form-card-quote {
    padding-top: 10% !important;
    height: 100vh !important;
    width: 100vw !important;
  }

  .quote-card-selection {
    height: 42vh !important;
    width: 25vh !important;
    padding: 3%;
    margin-left: 2vw !important;
    margin-right: 2vw !important;
  }

}


@media only screen and (max-width: 576px) and (max-height: 900px) {
  .quote-card {
    padding-top: 25vh !important;
  }

  .form-card-quote {
    padding-top: 18% !important;
    overflow-y: scroll;
    height: 110vh !important;
    width: 100vw !important;
  }

  .quote-card-selection {
    width: auto !important;
    padding: 3%;
    margin-top: 5vw;
    margin-bottom: 2vw;
    margin-left: auto;
    margin-right: auto;
  }

}


@media only screen and (max-width: 576px) and (max-height: 700px) {
  .quote-card {
    padding-top: 25vh !important;
  }

  .form-card-quote {
    padding-top: 10% !important;
    overflow-y: scroll;
    height: 100vh !important;
    width: 100vw !important;
  }

  .quote-card-selection {
    width: auto !important;
    padding: 3%;
    margin-top: 1vw;
    margin-bottom: 2vw;
    margin-left: auto;
    margin-right: auto;
  }

}


@media only screen and (max-width: 370px) and (max-height: 760px) {
  .quote-card {
    padding-top: 25vh !important;
  }

  .form-card-quote {
    padding-top: 10% !important;
    overflow-y: scroll;
    height: 100vh !important;
    width: 100vw !important;
  }

  .quote-card-selection {
    width: 60vw !important;
    margin-top: 1vw;
    margin-bottom: 2vw;
    margin-left: auto;
    margin-right: auto;
  }

}

@media only screen and (max-height: 550px) {
  .quote-card {
    padding-top: 60vh !important;
  }
}

@media only screen and (max-height: 650px) {
  .quote-card {
    padding-top: 30vh !important;
  }
}

@media only screen and (min-width: 577px) and (max-width: 768px) {
  .quote-perk-container{
    width: 40vw;
  }

  .perk-img-container {
    height: 70%;
  }

  .quote-card {
    width: 100% !important;
    height: 100% !important;
    padding-top: 30% !important;
    border: 0px !important;
  }

  .form-card-quote {
    padding-top: 10% !important;
    height: 100vh !important;
    width: 100vw !important;
    display: inline-block;
    overflow-y: scroll !important;
  }

  .package-name{
    margin-top: 1rem !important;
    font-size: 24px;
  }

  .pricing-container {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }

  .quote-card-selection {
    height: 42vh !important;
    width: 25vh !important;
    padding: 3%;
    margin-left: 5vw !important;
    margin-right: 5vw !important;
  }

  .retrieval-back {
      right: 30vw;
      top:3vh;
  }

  .top-row{
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: auto !important;
  }

  .price-unlimited{
    font-size: 40px;
    margin-bottom: 1px;
  }

  .price-lite{
    font-size: 40px;
  }

  .benefits-section {
    padding-top: 10px !important;
    padding-bottom: 2px !important;
    border-bottom: none !important;
  }

  .option-buttons {
    justify-content: space-between !important;
    height: 100% !important;
    width: 100% !important;
    display: flex !important;
  }

  .utility-font{
    font-size: 12px;
  }

  #house {
    display: none;
  }

  #house-big {
    left: 70vw !important;
  }

  .house-image-quote {
    height: 20vh !important;
  }

  #coins {
    display: none;
  }

  #coins-big {
    left: 20vw !important;
    top: 700px !important;
  }

  .coins-image {
    height: 12vh !important;
  }

  .container,
  .utility-row {
    display: flex !important;
    flex-direction: column !important;
  }

  .utility {
    margin-bottom: 1vh !important;
    margin-right: 0vw !important;
    margin-left: 0vw !important;
    width: 100%;
  }

  .background-stripe-left-quote {
    bottom: 5%;
    left: 0vw;
    z-index: 0;
  }

  .background-stripe-right-quote {
    left: 56vw;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1100px) {
  .quote-card {
    width: 100% !important;
    height: 100% !important;
    padding-top: 30% !important;
    border: 0px !important;
  }

  .form-card-quote {
    width: 100vw !important;
    height: 90vh !important;
    display: inline;
    overflow-y: visible !important;
    margin-top: 10vh !important;
  }

  .package-name{
    margin-top: 1rem !important;
    font-size: 24px;
  }

  .pricing-container {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }

  .quote-card-selection {
    height: 42vh !important;
    width: 30vh !important;
    padding: 3%;
    margin-left: 5vh !important;
    margin-right: 5vh !important;
  }

  .top-row{
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: auto !important;
  }

  .price-unlimited{
    font-size: 40px;
    margin-bottom: 1px;
  }

  .price-lite{
    font-size: 40px;
  }

  .benefits-section {
    padding-top: 10px !important;
    padding-bottom: 2px !important;
    border-bottom: none !important;
  }

  .option-buttons {
    justify-content: space-between !important;
    height: 100% !important;
      flex-direction: row !important;
      width: 100% !important;
      display: flex !important;
  }

  .utility-font{
    font-size: 12px;
  }

  #house {
    display: none;
  }

  #house-big {
    left: 70vw !important;
  }

  .house-image-quote {
    height: 30vh !important;
  }

  #coins {
    display: none;
  }

  #coins-big {
    left: 10vw !important;
    top: 600px !important;
  }

  .coins-image {
    height: 18vh !important;
  }

  .container,
  .utility-row {
    display: flex !important;
    flex-direction: column !important;
  }

  .utility {
    margin-bottom: 0.5vh !important;
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
    padding-right: 1rem !important;
    padding-left: 1rem !important;
    margin-right: 0.5rem !important;
    width: 100% !important;
  }

  .continue {
    width: 40%;
    height: 20vh !important;
    padding-bottom: 0.5rem !important;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
    margin-right: auto !important;
    margin-left: auto !important;
    justify-content: space-evenly !important;
    flex-direction: column !important;
    display: flex !important;
  }

  .background-stripe-left-quote {
    bottom: 5%;
    right: 15vw !important;
    z-index: 0;
  }

  .background-stripe-right-quote {
    right: 0vw !important;
    top: 10vh !important;
  }
}

@media only screen and (min-width: 1101px) and (max-width: 1200px) {
  .quote-card {
    width: 100% !important;
    height: 100% !important;
    padding-top: 10% !important;
    border: 0px !important;
  }

  .quote-card-selection {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    background-color: white;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 0% !important;
    padding-bottom: 0% !important;
    width: 30vw !important;
    margin-left: 3vw;
    margin-right: 3vw;
  }
  
  .form-card-quote {
    width: 100vw !important;
    height: 90vh !important;
    overflow-y: visible !important;
    margin-top: 10vh !important;
  }

  #house {
    display: none;
  }

  #house-big {
    left: 70vw !important;
  }

  .house-image-quote {
    height: 30vh !important;
  }

  #coins {
    display: none;
  }

  #coins-big {
    left: 16vw !important;
    top: 550px !important;
  }

  .coins-image {
    height: 20vh !important;
  }

  .background-stripe-left-quote {
    right: 10vw !important;
    z-index: 1;
    bottom: 10vh;
  }

  .background-stripe-right-quote {
    left: 72vw !important;
    z-index: 0;
    top: 10vh;
  }

  .utility {
    width: 85%;
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1400px) {
  .quote-card {
    width: 100% !important;
    height: 100% !important;
    padding-top: 10% !important;
    border: 0px !important;
  }

  .form-card-quote {
    width: 100% !important;
    height: 100vh !important;
    overflow-y: scroll !important;
    margin-top: 5vh !important;
  }

  .quote-card-selection {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    background-color: white;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30vw !important;
    margin-left: 3vw;
    margin-right: 3vw;
    padding-top: 0% !important;
    padding-bottom: 0% !important;

  }

  #house {
    display: none;
  }

  #house-big {
    left: 70vw !important;
  }

  .house-image-quote {
    height: 40vh !important;
  }

  #coins {
    display: none;
  }

  #coins-big {
    left: 16vw !important;
    top: 550px !important;
  }

  .coins-image {
    height: 20vh !important;
  }

  .background-stripe-left-quote {
    z-index: 1;
    bottom: 5vh;
  }

  .background-stripe-right-quote {
    bottom: 0vh !important;
    right: 0vw;
    z-index: 1;
    top: 15vh;
  }

  .utility {
    width: 80%;
  }
}

@media only screen and (min-width: 1401px) {
  .form-card-quote {
    width: 100% !important;
    overflow-y: scroll !important;
    margin-top: 5vh !important;
  }

  .quote-card-selection {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    background-color: white;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30vw !important;
    margin-left: 3vw;
    margin-right: 3vw;
    padding-top: 0% !important;
    padding-bottom: 0% !important;
  }

  #house {
    display: none;
  }

  #coins {
    display: none;
  }

  .background-stripe-left-quote {
    z-index: 1;
    bottom: 7vh;
  }

  .background-stripe-right-quote {
    top: 13vh !important;
    right: -0vw !important;
    z-index: 1;
  }
}

@media only screen and (max-width: 576px) {
  .quote-perk-container {
    width: 60vw
  }

  .perk-img-container {
    height: 60%;
  }
}